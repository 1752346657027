
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { ScrollComponent } from "@/assets/ts/components/_ScrollComponent";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";
import MainMenuConfig from './Menu';
import { VueCookieNext } from 'vue-cookie-next';
export default defineComponent({
  name: 'kt-menu',
  data() {
    return {
      MainMenuConfig: [
        {
          pages: [
            // {
            //   heading: 'Add Employment',
            //   route: '/job-placement/self-employment',
            //   fontIcon: 'fa-plus',
            // },
            {
              heading: 'Job History',
              route: '/job-placement/job-history',
              fontIcon: 'fa-info',
            },
            {
              heading: 'Job Tracking',
              route: '/job-placement/job-tracking',
              fontIcon: 'fa-search',
            },
            {
              heading: 'Batch Wise Job Placement Summary',
              route: '/job-placement/employment-information',
              fontIcon: 'fa-list',
              svgIcon: "media/icons/duotune/general/gen019.svg",
            },
          ],
        },
      ],
    };
  },
  components: {},
  async created() {
    await this.actioncheck();
  },
  methods: {
    async actioncheck() {
      let menu = JSON.parse(localStorage.getItem('menu') || '{}');
      if (menu) {
        for (let i = 0; i < menu.length; i++) {
          if (menu[i].heading == 'Batch') {
            let actions = menu[i].action;
            for (let j = 0; j < actions.length; j++) {
              if (actions[j].action_name === 'Create') {
                this.MainMenuConfig = [
                  {
                    pages: [
                      {
                        heading: 'Add Employment',
                        route: '/job-placement/self-employment',
                        fontIcon: 'fa-plus',
                      },
                      {
                        heading: 'Job History',
                        route: '/job-placement/job-history',
                        fontIcon: 'fa-info',
                      },
                      {
                        heading: 'Job Tracking',
                        route: '/job-placement/job-tracking',
                        fontIcon: 'fa-search',
                      },
                      {
                        heading: 'Batch Wise Job Placement Summary',
                        route: '/job-placement/employment-information',
                        fontIcon: 'fa-list',
                        svgIcon: "media/icons/duotune/general/gen019.svg",
                      },
                    ],
                  },
                ];
              }
            }
          }
        }
      }
    },
  },
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);

    onMounted(() => {
      ScrollComponent.reinitialization();
      MenuComponent.reinitialization();
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    return {
      hasActiveChildren,
      asideMenuIcons,
      version,
      translate,
    };
  },
});
